<template>
  <div class="moneyFlowing" v-loading.fullscreen.lock='loading' element-loading-text="拼命加载中"
  element-loading-background="rgba(255, 255, 255, 0.5)">
    <div style="display:flex;justify-content:space-between">
      <div class="search">
        <el-select clearable v-model="pageInfo.type" placeholder="收支类型" @change="search">
          <el-option v-for="item in payCollectTypeList" :key="item.id" :label="item.value" :value="item.id"></el-option>
        </el-select>
        <el-select clearable v-model="pageInfo.payCollectType" placeholder="收/付款类型">
          <el-option-group
            v-for="group in payCollect"
            :key="group.name"
            :label="group.name">
            <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.value"
              :value="item.id">
            </el-option>
          </el-option-group>
        </el-select>
        <el-select clearable v-model="pageInfo.autoCreate" placeholder="数据来源" @change="search">
          <el-option v-for="item in dateFromList" :key="item.id" :label="item.value" :value="item.id"></el-option>
        </el-select>
        <el-select clearable v-model="pageInfo.currency" placeholder="货币" @change="search">
          <el-option v-for="item in currencyTypeList" :key="item.id" :label="item.value" :value="item.id"></el-option>
        </el-select>
        <el-input v-model="pageInfo.keyword"  placeholder="请输入搜索内容" @keyup.enter.native = "search"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
      </div>
      <div style="display:flex;justify-content:space-around;align-items:center;width:130px">
        <TableDialog :tableList='tableList' @setTableHead="setTableHead"  />
        <el-tooltip content="生成报告" placement="top">
            <div class="setting el-icon-receiving" @click="report"></div>
        </el-tooltip>
        <el-tooltip content="下载报告" placement="top">
            <div class="setting el-icon-download" @click="exportTable"></div>
        </el-tooltip>
      </div>
    </div>
    <el-dialog
        title="收款单-下载报告"
        :visible.sync="downReportBox"
        width="width"
        :before-close="downClose">
        <el-table
            :data="downList"
            border
            cell-style="text-align:center"
            header-cell-style="text-align:center;background:#E4E7ED"
            style="width: 100%">
            <el-table-column
                prop="createTime"
                label="申请时间"
                width="width">
            </el-table-column>
            <el-table-column
                prop="createBy"
                label="操作者"
                width="width">
            </el-table-column>
            <el-table-column
                prop="status"
                label="状态"
                width="width">
            </el-table-column>
            <el-table-column
                label="操作"
                width="width">
                <template slot-scope="scope">
                    <el-button :disabled="scope.row.status=='队列中'||scope.row.status=='下载失败'?true:false" type="text" @click="down(scope.row.fileUrl)">下载</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;margin:20px">
            <!-- <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400">
            </el-pagination> -->
        </div>
        <div slot="footer">
            <el-button @click="downReportBox = false">取 消</el-button>
            <el-button type="primary" @click="downReportBox = false">确 定</el-button>
        </div>
    </el-dialog>
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      header-cell-style="text-align:center;background:#E4E7ED"
      cell-style="text-align:center">
      <el-table-column
        v-for="item in tableHead"
        :key="item.name"
        :prop="item.prop"
        :label="item.name"
        :width="item.width">
      </el-table-column>
    </el-table>
    <div style="text-align:right;margin:10px 20px 0 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.current"
        :page-sizes="[15, 20, 30, 40]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import TableDialog from '@/components/tableDialog.vue'
import {
  pageInit, 
  listPage,
  exportExcel,
  exportRecort
} from '@/api/financialManage/moneyFlowing.js'
export default {
  components:{
    TableDialog
  },
  data(){
    return {
      loading:false,
      tableData:[],
      pageInfo:{
        type:'',//收支类型
        payCollectType:'',//收付款类型
        current:'',//当前页
        autoCreate:'',//数据来源
        currency:'',//货币
        keyword:'',//关键字
        pageSize:'15'//页面大小
      },
      total:'',
      tableHead:[],
      tableList:[
        {name:'收/付款单号',prop:'payCollectNo'},
        {name:'关联单号',prop:'relatedBillNo'},
        {name:'收支类型',prop:'type'},
        {name:'币种',prop:'currency'},
        {name:'金额',prop:'amount'},
        {name:'收/付款类型',prop:'payCollectType'},
        {name:'付款账户名称',prop:'payAccountName'},
        {name:'收款账户名称',prop:'collectAccountName'},
        {name:'交易时间',prop:'createTime'},
      ],
      // collectTypeList:[],
      dateFromList:[],
      payCollectTypeList:[],
      // payTypeList:[],
      currencyTypeList:[],
      payCollect:[],
      downReportBox:false,
      downList:[]

    }
  },
  watch:{
    // pageInfo:{
    //   handler(newval,oldval){
    //     if(newval.payCollectType==206||newval.payCollectType==207||newval.payCollectType==211||newval.payCollectType==214)
    //     {
    //       this.pageInfo.type=0
    //     }
    //     if(newval.payCollectType==158||newval.payCollectType==159||newval.payCollectType==205||newval.payCollectType==227||newval.payCollectType==240)
    //     {
    //       this.pageInfo.type=1
    //     }
    //   },
    //   deep:true
    // }
  },
  created(){
    this.tableHead=this.tableList
    this.getPageInit()
    this.getListPage()
  },
  methods:{
    handleSizeChange(val){
      this.pageInfo.pageSize=val
      this.getListPage()
    },
    handleCurrentChange(val){
      this.pageInfo.current=val
      this.getListPage()
    },
    async getPageInit(){
      const {data} = await pageInit(this.pageInfo)
      this.dateFromList=data.data.dateFromList
      this.payCollectTypeList=data.data.payCollectTypeList
      this.currencyTypeList=data.data.currencyTypeList
      this.payCollect=[
        {
          name:'收款类型',
          options:data.data.collectTypeList
        },
        {
          name:'付款类型',
          options:data.data.payTypeList
        }
      ]
    },
    async getListPage(){
      this.loading=true
      const {data} = await listPage(this.pageInfo)
      this.tableData=data.values
      this.total=data.pageInfo.total
      this.loading=false
      // this.pageInfo={
      //   type:'',//收支类型
      //   payCollectType:'',//收付款类型
      //   current:'',//当前页
      //   autoCreate:'',//数据来源
      //   currency:'',//货币
      //   keyword:'',//关键字
      //   pageSize:'15'//页面大小
      // }
    },
    setTableHead(val){
      this.tableHead=val
    },
     report(){
        this.$confirm('确认生成收款单报告？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        type: 'warning'
        }).then(() => {
            exportExcel({
                type:this.pageInfo.type,
                payCollectType:this.pageInfo.payCollectType,
                autoCreate:this.pageInfo.autoCreate,
                currency:this.pageInfo.currency,
                keyword:this.pageInfo.keyword
            })
            this.$alert('导出中，请稍等数分钟后下载，您可以在“下载报告中”查况并下载', '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    // this.$message({
                    // type: 'info',
                    // message: `action: ${ action }`
                    // });
                    return ;
                }
            });
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消生成'
            });          
        });
    },
    //导出
    exportTable(){
        this.downReportBox=true
        exportRecort().then(res=>{
            // if(res.data.code==200){
                this.downList=res.data.data.data
            // }
        })
    },
    down(url){
        const link = document.createElement('a')
        document.body.appendChild(link)
        link.href =url
        link.click()
        document.body.removeChild(link)
    },
    async search(){
      this.loading=true
      this.pageInfo.current=1
      const {data} = await listPage({...this.pageInfo,current:1})
      this.tableData=data.values
      this.total=data.pageInfo.total
      this.loading=false
      // this.pageInfo={
      //   type:'',//收支类型
      //   payCollectType:'',//收付款类型
      //   current:'',//当前页
      //   autoCreate:'',//数据来源
      //   currency:'',//货币
      //   keyword:'',//关键字
      //   pageSize:'15'//页面大小
      // }
    }
  }
}
</script>

<style scoped lang='scss'>
.search>div{
  margin: 0 10px 20px 0;
  width: 150px;
}
.setting{
    color:#409EFF;
    font-size:25px;
    border:2px solid #409EFF;
    border-radius: 6px;
    overflow: hidden;
    width: 25px;
    height: 25px;
}
</style>